import {
  Box,
  Avatar,
  Skeleton,
  Card,
  CardHeader,
  CardActions,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { CreateExperienceDialog } from './components/CreateExperienceDialog';
import { useEffect, useState } from 'react';
import useExperiences from '../../../queryHelpers/queries/useExperiences';
import { routes } from '../../../routes';
import { PaperBackground } from '../styled/PaperBackground';
import { useAdminOutletContext } from '../AdminLayout';

export default function ExperiencesPage() {
  const navigate = useNavigate();
  const [openCreateExperience, setOpenCreateExperience] = useState(false);
  const outletContext = useAdminOutletContext();

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: 'Услуги',
      },
      BottomAppBar: {
        fab: {
          title: 'Создать',
          icon: <AddIcon />,
          onClick: () => setOpenCreateExperience(true),
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetched } = useExperiences();
  if (!isFetched) {
    return (
      <>
        <Skeleton variant="circular" width={50} height={50}>
          <Avatar />
        </Skeleton>
        <Skeleton />
      </>
    );
  }

  return (
    <>
      <PaperBackground>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'flex-start',
            px: 2,
          }}
        >
          {data!.map((experience, index) => (
            <Box
              sx={{
                width: {
                  xs: 1,
                  md: 300,
                },
              }}
            >
              <Card key={index}>
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        fontSize: 20,
                      }}
                    >
                      {experience.summary.title[0]}
                    </Avatar>
                  }
                  title={experience.summary.title}
                  subheader={'услуга'}
                />
                <CardActions disableSpacing>
                  <Button
                    size="small"
                    onClick={() =>
                      navigate(routes.admin.experienceDetail(experience.id))
                    }
                  >
                    Редактировать
                  </Button>
                </CardActions>
              </Card>
            </Box>
          ))}
        </Box>

        <CreateExperienceDialog
          open={openCreateExperience}
          onClose={() => setOpenCreateExperience(false)}
        />
      </PaperBackground>
    </>
  );
}
