import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { format } from 'date-fns';
import { BookingFormFormInput } from './BookingForm';
import { Typography } from '@mui/material';
import { timeToString } from '../../helpers/timeHelpers';
import { SummaryVM } from '../../viewModels/experienceViewModel';

interface BookingConfirmationDialogProps {
  booking: BookingFormFormInput | null;
  experience: SummaryVM | null;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const BookingConfirmationDialog: React.FC<BookingConfirmationDialogProps> = (
  props,
) => {
  // const [book, setBook] = useState<Book>({ title: '', author: '', isbn: '' });

  const handleCancel = () => {
    props.onCancel();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <Dialog open={props.open} onClose={handleCancel}>
      <DialogTitle>Проверьте и подтвердите</DialogTitle>
      <DialogContent>
        {props.booking && props.experience && (
          <>
            <DialogContentText>
              <Typography variant="body1">
                Запись на "{props.experience.title}" на{' '}
                {format(new Date(props.booking.date!), 'PPPP')} с{' '}
                {timeToString(props.booking.timeSlot?.start!)} до{' '}
                {timeToString(props.booking.timeSlot?.end!)}.
              </Typography>
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Назад</Button>
        <Button onClick={handleConfirm}>Подтвердить</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingConfirmationDialog;
