import React from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import AttachGoogleAccountButton from '../../../../../components/AttachGoogleAccountButton';
import { useGoogleUser } from '../../../../../components/GoogleClientProvider';

type GoogleCalendarSettingsViewEditProps = {
  userId: string;
};

const GoogleCalendarSettingsViewEdit: React.FC<
  GoogleCalendarSettingsViewEditProps
> = (props) => {
  const googleUser = useGoogleUser();

  return (
    <ViewEditControl
      title="Google календарь"
      editMode="inline"
      viewFields={[
        {
          icon: <CalendarMonthIcon />,
          label: 'User',
          value: googleUser?.getBasicProfile().getEmail() || 'Not connected', // Display Google user email
        },
        // Removed connected calendars count
      ]}
      editor={<AttachGoogleAccountButton />}
      editorIsValid={true}
      onCancel={() => {}}
      onSave={async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Implement your save logic here
        await Promise.resolve();
      }}
    />
  );
};

export default GoogleCalendarSettingsViewEdit;
