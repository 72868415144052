import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useExperience from '../../../queryHelpers/queries/useExperience';
import ViewDetailsSectionList from '../styled/ViewDetailsSectionList';
import SummaryViewEdit from './components/summary/SummaryViewEdit';
import WeekDayAvailabilityViewEdit from './components/dateAvailability/DateAvailabilityViewEdit';
import LimitSettingsViewEdit from './components/limitSettings/LimitSettingsViewEdit';
import GoogleCalendarIntegrationViewEdit from './components/googleCalendar/GoogleCalendarIntegrationViewEdit';
import { TopAppBarActionItem, useAdminOutletContext } from '../AdminLayout';
import { routes } from '../../../routes';
import { Delete } from '@mui/icons-material';
import useDeleteExperience from '../../../queryHelpers/mutations/useDeleteExperience';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { IosShare } from '@mui/icons-material';
import { getBookLink } from '../../../helpers/linkHelpers';

interface ExperienceDetailPageProps {
  /*...*/
}

const ExperienceDetailPage: React.FC<ExperienceDetailPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { isFetched, data } = useExperience({ id: id });
  const outletContext = useAdminOutletContext();
  const navigate = useNavigate();

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const deleteMutation = useDeleteExperience({
    id: id!,
  });
  const handleConfirmDelete = () => {
    deleteMutation.mutate(undefined, {
      onSuccess: () => {
        navigate(routes.admin.experiences);
      },
    });
  };

  const topBarActionItems: TopAppBarActionItem[] = [];
  if (navigator.share) {
    topBarActionItems.push({
      icon: <IosShare />,
      label: 'Поделиться',
      onClick: async () => {
        if (!data) return;
        await navigator.share({
          title: 'Ссылка для онлайн записи',
          text: `Ссылка для онлайн записи на "${data!.summary.title}"`,
          url: getBookLink(data!),
        });
      },
    });
  }
  topBarActionItems.push({
    icon: <Delete />,
    label: 'Удалить',
    onClick: () => setOpenDeleteDialog(true),
  });

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: 'Услуга',
        backButtonPath: routes.admin.experiences,
        actionItems: topBarActionItems,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isFetched) {
    return <></>;
  }

  return (
    <>
      <Container>
        <ViewDetailsSectionList>
          <SummaryViewEdit experienceId={id!} />
          <WeekDayAvailabilityViewEdit experienceId={id!} />
          <LimitSettingsViewEdit experienceId={id!} />
          <GoogleCalendarIntegrationViewEdit experienceId={id!} />
        </ViewDetailsSectionList>

        <ConfirmationDialog
          title="Удалить услугу?"
          description="Вы уверены, что хотите удалить услугу? Это действие нельзя будет отменить."
          open={openDeleteDialog}
          onConfirm={handleConfirmDelete}
          onCancel={() => setOpenDeleteDialog(false)}
        />
      </Container>
    </>
  );
};

export default ExperienceDetailPage;
