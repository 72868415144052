import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseConfig';
import { AuthTelegramRequest } from 'shared-ts/functions/authTelegram.dto';
import { AuthVkRequest } from 'shared-ts/functions/authVk.dto';
import { CreateAccountRequest } from 'shared-ts/functions/createAccount.dto';
import {
  CreateExperienceRequest,
  CreateExperienceResponse,
} from 'shared-ts/functions/createExperience.dto';
import { UpdateExperienceRequest } from 'shared-ts/functions/updateExperience.dto';
import { DeleteExperienceRequest } from 'shared-ts/functions/deleteExperience.dto';
import {
  CreateBookingRequest,
  CreateBookingResponse,
} from 'shared-ts/functions/createBooking.dto';
import { UpdateBookingRequest } from 'shared-ts/functions/updateBooking.dto';
import { UpdateUserRequest } from 'shared-ts/functions/updateUser.dto';
import { DeleteBookingRequest } from 'shared-ts/functions/deleteBooking.dto';
import {
  GetAvailableTimeSlotsRequest,
  GetAvailableTimeSlotsResponse,
} from 'shared-ts/functions/getAvailableTimeSlots.dto';
import { SetGoogleIntegrationRequest, SetGoogleIntegrationResponse } from 'shared-ts/functions/authGoogle.dto';

export const TelegramAuthFunc = httpsCallable<AuthTelegramRequest>(
  functions,
  'AuthTelegram',
);
export const VkAuthFunc = httpsCallable<AuthVkRequest>(functions, 'AuthVk');
export const CreateAccountFunc = httpsCallable<CreateAccountRequest>(
  functions,
  'CreateAccount',
);

// Adding the missing functions
export const CreateExperienceFunc = httpsCallable<
  CreateExperienceRequest,
  CreateExperienceResponse
>(functions, 'CreateExperience');
export const UpdateExperienceFunc = httpsCallable<UpdateExperienceRequest>(
  functions,
  'UpdateExperience',
);
export const DeleteExperienceFunc = httpsCallable<DeleteExperienceRequest>(
  functions,
  'DeleteExperience',
);

export const UpdateUserFunc = httpsCallable<UpdateUserRequest>(
  functions,
  'UpdateUser',
);

export const CreateBookingFunc = httpsCallable<
  CreateBookingRequest,
  CreateBookingResponse
>(functions, 'CreateBooking');

export const UpdateBookingFunc = httpsCallable<UpdateBookingRequest>(
  functions,
  'UpdateBooking',
);

export const DeleteBookingFunc = httpsCallable<DeleteBookingRequest>(
  functions,
  'DeleteBooking',
);

export const GetAvailableTimeSlotsFunc = httpsCallable<
  GetAvailableTimeSlotsRequest,
  GetAvailableTimeSlotsResponse
>(functions, 'GetAvailableTimeSlots');

export const SetGoogleIntegrationFunc = httpsCallable<SetGoogleIntegrationRequest, SetGoogleIntegrationResponse>(functions, 'SetGoogleIntegration');

export const AddGoogleCalendarConnectionFunc = httpsCallable(functions, 'AddGoogleCalendarConnection');


