import React from 'react';
import WorkIcon from '@mui/icons-material/WorkOutline';
import CommentBankIcon from '@mui/icons-material/CommentBankOutlined';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import useBooking from '../../../../../queryHelpers/queries/useBooking';
import useExperience from '../../../../../queryHelpers/queries/useExperience';

type BookingScheduleViewEditProps = {
  bookingId: string;
};

const BookingSummaryViewEdit: React.FC<BookingScheduleViewEditProps> = (
  props,
) => {
  const bookingQuery = useBooking(props.bookingId);
  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId as string,
    enabled: !!bookingQuery.data,
  });

  return (
    <>
      <ViewEditControl
        title="Запись"
        editMode="inline"
        viewFields={[
          {
            icon: <WorkIcon />,
            label: 'Услуга',
            value: experienceQuery.data?.summary.title,
          },
          {
            icon: <CommentBankIcon />,
            label: 'Пожелания',
            value: bookingQuery.data?.wishes || 'Не указано',
          },
        ]}
        onSave={async (e) => {}}
        onCancel={() => {}}
      />
    </>
  );
};

export default BookingSummaryViewEdit;
