import { Button, Container } from '@mui/material';
import { DataStorage } from '../../../data/dataStorage';
import { useAdminOutletContext } from '../AdminLayout';
import { useEffect } from 'react';

const DeveloperPage = () => {
  const outletContext = useAdminOutletContext();
  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: 'Разработчик',
      },
    });
  });

  const handleReset = async () => {
    await DataStorage.reset();
    alert('DataStorage has been reset');
  };

  return (
    <Container>
      <h1>Developer Page</h1>
      <Button variant="contained" color="primary" onClick={handleReset}>
        Reset DataStorage
      </Button>
    </Container>
  );
};

export default DeveloperPage;
