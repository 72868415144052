import { gapi, loadClientAuth2 } from 'gapi-script';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from 'react';
import { calendar_v3 } from '@googleapis/calendar/v3';
import { useAppSelector } from '../hook';
import useAccount from '../queryHelpers/queries/useAccount';

interface GoogleClient {
  auth: gapi.auth2.GoogleAuth;
  calendar: calendar_v3.Calendar;
}

interface GoogleClientContextType {
  client: GoogleClient | null;
  currentUser: gapi.auth2.GoogleUser | null;
}

export const GoogleClientContext = createContext<
  GoogleClientContextType | undefined
>(undefined);

// New hook to use the GoogleScriptContext
export const useGoogleClient = () => {
  const context = useContext(GoogleClientContext);
  if (!context) {
    throw new Error(
      'useGoogleClient must be used within a GoogleClientProvider',
    );
  }
  return context.client;
};

export const useGoogleUser = () => {
  const context = useContext(GoogleClientContext);
  if (!context) {
    throw new Error(
      'useGoogleClient must be used within a GoogleClientProvider',
    );
  }
  return context.currentUser;
}

const GoogleClientProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [client, setClient] = useState<GoogleClient | null>(null);
  const [googleUser, setGoolgeUser] = useState<gapi.auth2.GoogleUser | null>(null);

  const user = useAppSelector((x) => x.auth.user);
  const account = useAccount(user?.accountId);

  useEffect(() => {
    const initializeGoogleApi = async () => {
      await loadClientAuth2(
        gapi,
        process.env.REACT_APP_GOOGLE_CLIENT_ID!,
        'https://www.googleapis.com/auth/calendar',
      );

      await gapi.client.load('calendar', 'v3');

      const authInstance = gapi.auth2.getAuthInstance();
      const calendar = (gapi.client as any).calendar;

      setClient({ auth: authInstance, calendar });
    };

    initializeGoogleApi();
  }, []);

  useEffect(() => {
    if (!account.data?.googleAccount || !client?.auth) {
      setGoolgeUser(null);
      return;
    }

    gapi.client.setToken({
      access_token: account.data?.googleAccount?.accessToken!,
    });

    const googleUser = client?.auth.currentUser.get();
    setGoolgeUser(googleUser);

  }, [account.data?.googleAccount, client]);

  return (
    <GoogleClientContext.Provider value={{ client, currentUser: googleUser }}>
      {children}
    </GoogleClientContext.Provider>
  );
};

export default GoogleClientProvider;
