import React from 'react';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import BookingClientEditor from './BookingClientEditor';
import * as yup from 'yup';
import { useEditForm } from '../../../../../hooks/useEditForm';
import useBooking from '../../../../../queryHelpers/queries/useBooking';
import useUpsertBooking from '../../../../../queryHelpers/mutations/useUpsertBooking';

type BookingClientViewEditProps = {
  bookingId: string;
};

const BookingClientFormSchema = yup
  .object({
    name: yup.string().required('Введите имя'),
    phone: yup
      .string()
      .min(11, 'Введите полный номер телефона')
      .required('Введите номер телефона'),
  })
  .required();

export type BookingClientFormInputs = {
  name: string;
  phone: string;
};

const BookingClientViewEdit: React.FC<BookingClientViewEditProps> = (props) => {
  const bookingQuery = useBooking(props.bookingId);
  const appForm = useEditForm<BookingClientFormInputs>({
    defaultValues: {
      name: bookingQuery.data?.name || '',
      phone: bookingQuery.data?.phone || '',
    },
    formSchema: BookingClientFormSchema,
  });

  const upsertBookingMutation = useUpsertBooking();

  const onSubmit = (data: BookingClientFormInputs) => {
    upsertBookingMutation.mutate({
      ...data,
      id: props.bookingId,
    });
  };

  return (
    <ViewEditControl
      title="Клиент"
      editMode="inline"
      viewFields={[
        {
          icon: <PersonIcon />,
          label: 'Имя',
          value: bookingQuery.data?.name || '',
        },
        {
          icon: <LocalPhoneIcon />,
          label: 'Телефон',
          value: bookingQuery.data?.phone ? '+' + bookingQuery.data?.phone : '',
        },
      ]}
      editor={<BookingClientEditor form={appForm.form} />}
      editorIsValid={appForm.form.formState.isValid}
      onCancel={appForm.handleCancel}
      onSave={appForm.form.handleSubmit(onSubmit)}
    />
  );
};

export default BookingClientViewEdit;
