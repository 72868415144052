import { configureStore } from '@reduxjs/toolkit'
import auth_reducer, { actualizeStatus } from './slicers/authSlice'
import { auth } from './firebaseConfig';

export const store = configureStore({
  reducer: {
    auth: auth_reducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

auth.onAuthStateChanged((_) => {
  store.dispatch(actualizeStatus());
});

