import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { DeleteBookingFunc } from '../../firebaseFunctions';
import { DeleteBookingRequest } from 'shared-ts/functions/deleteBooking.dto';

function useDeleteBooking(props: { id: string }) {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, Error, void, unknown>({
    mutationFn: async () => {
      const deleteRequest: DeleteBookingRequest = {
        id: props.id,
      };
      await DeleteBookingFunc(deleteRequest);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.bookings],
      });
    },
  });

  return mutation;
}

export default useDeleteBooking;
