import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import React, { RefObject, createRef } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from 'react-router-dom';
import './App.css';
import LandingPage from './pages/landing/LandingPage';
import LoginPage from './pages/login/LoginPage';
import BookingConfirmationPage from './pages/book/BookingConfirmationPage';
import BookingsPage from './pages/admin/bookings/BookingsPage';
import AdminLayout from './pages/admin/AdminLayout';
import BookingProcessPage from './pages/book/BookingProcessPage';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ru } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import ExperiencesPage from './pages/admin/experiences/ExperiencesPage';
import ExperienceDetailPage from './pages/admin/experiences/ExperienceDetailPage';
import BookingDetailPage from './pages/admin/bookings/BookingDetailPage';
import SettingsPage from './pages/admin/settings/SettingsPage';
import DeveloperPage from './pages/admin/developer/DeveloperPage';
import { flatMapDeep, uniqBy } from 'lodash';
import { AgnosticDataRouteObject } from '@remix-run/router';
import { auth } from './firebaseConfig';
import LandingLayout from './pages/landing/LandingLayout';
import LandingApp from './pages/landing/LandingApp';
import VkAuthRedirectPage from './libs/vkLogin/VkAuthRedirectPage';
import { routes } from './routes';
import LoginAccountPage from './pages/login/LoginAccountPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

setDefaultOptions({ locale: ru });

function waitForUser(): Promise<any> {
  return new Promise((resolve) => {
    const checkUser = () => {
      const state = store.getState();
      const user = state.auth.user;
      if (user) {
        resolve(user);
      } else {
        setTimeout(checkUser, 100); // Check again after 100ms
      }
    };
    checkUser();
  });
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route element={<LandingApp />}>
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<LandingPage />} />
        </Route>
      </Route>
      <Route
        element={<AdminLayout />}
        loader={async () => {
          await auth.authStateReady();

          if (!auth.currentUser) {
            throw redirect('/login');
          }

          await waitForUser();

          if (!store.getState().auth.user) {
            throw redirect('/login');
          }

          if (!store.getState().auth.user?.accountId) {
            throw redirect('/loginAccount');
          }

          return null;
        }}
        path="admin"
      >
        <Route index element={<Navigate to="/admin/bookings" />} />
        <Route path="bookings" element={<BookingsPage />} />
        <Route path="bookings/:id" element={<BookingDetailPage />} />
        <Route path="experiences" element={<ExperiencesPage />} />
        <Route path="experiences/:id" element={<ExperienceDetailPage />} />
        <Route path="experiences/new" element={<ExperienceDetailPage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="developer" element={<DeveloperPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/loginAccount" element={<LoginAccountPage />} />
      <Route
        path="/vkAuthRedirect"
        element={<VkAuthRedirectPage loginRedirect={routes.admin.index} />}
      />
      <Route
        path="/booking/:bookingId/success"
        element={<BookingConfirmationPage />}
      />
      <Route path="/book/:experienceId" element={<BookingProcessPage />} />
    </Route>,
  ),
);

type RouteRef = {
  path: string;
  regexPath: RegExp;
  nodeRef: RefObject<HTMLElement | undefined>;
};

function createRegExp(path: string): RegExp {
  const parts = path.replace(/\/:([^/]+)/g, '/.+');
  return new RegExp(parts);
}

function flattenRoutes(
  routes: AgnosticDataRouteObject[],
  prefix: string = '',
): RouteRef[] {
  return uniqBy(
    flatMapDeep(routes, (route) => {
      const path = ('/' + prefix + '/' + (route.path ?? ''))
        .replace('///', '/')
        .replace('//', '/');

      const newRoute: RouteRef = {
        path: path,
        regexPath: createRegExp(path),
        nodeRef: createRef(),
      };

      return route.children
        ? [newRoute, ...flattenRoutes(route.children, route.path)]
        : newRoute;
    }),
    'path',
  ).sort((a, b) => b.path.length - a.path.length);
}

export const routesRefs: RouteRef[] = flattenRoutes(router.routes);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
);
