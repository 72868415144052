import TelegramLoginButton from '../telegramLoginButton/TelegramLoginButton';
import { logout } from '../../slicers/authSlice';
import { useAppDispatch, useAppSelector } from '../../hook';
import { Avatar, Chip } from '@mui/material';

export default function UserLoginStatus() {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      {user ? (
        <>
          <Chip
            avatar={
              <Avatar alt={user.name} src="/static/images/avatar/1.jpg" />
            }
            label={user.name}
            variant="outlined"
            onDelete={handleLogout}
          />
        </>
      ) : (
        <></>
      )}
      <TelegramLoginButton />
    </>
  );
}
