import React from 'react';
import { Checkbox, FormControlLabel, Grid, Slider } from '@mui/material';
import {
  pluralBookings,
  pluralDays,
  pluralTime,
} from '../../../../../helpers/pluralHelpers';
import TimeSlider from './TimeSlider';
import { Label } from '../../../styled/Label';
import { LimitSettingsFormInputs } from './LimitSettingsViewEdit';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

interface LimitSettingsEditorProps {
  register: UseFormRegister<LimitSettingsFormInputs>;
  errors: FieldErrors<LimitSettingsFormInputs>;
  watch: UseFormWatch<LimitSettingsFormInputs>;
  control: Control<LimitSettingsFormInputs>;
  autoLink?: boolean;
}

const LimitSettingsEditor: React.FC<LimitSettingsEditorProps> = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>
          Запись окрыта на следующие{' '}
          {pluralDays(props.watch('futureBookingDaysLimit')!)}
        </Label>
        <Slider
          {...props.register('futureBookingDaysLimit')}
          step={1}
          min={1}
          max={90}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>
          Записываться за {pluralTime(props.watch('minimumNoticePeriod')!)}
        </Label>
        <Controller
          control={props.control}
          name="minimumNoticePeriod"
          defaultValue={0}
          render={({ field }) => (
            <TimeSlider
              value={field.value!}
              onChange={(e, value) => field.onChange(value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>
          Всего {pluralBookings(props.watch('dailyBookingLimit')!)} в день
        </Label>
        <Slider
          {...props.register('dailyBookingLimit')}
          step={1}
          min={1}
          max={20}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>
          {Number(props.watch('gapBetweenEvents')) ? pluralTime(props.watch('gapBetweenEvents')!) + " перерыва между записями" : "Без перерыва между записями"}
        </Label>
        <Slider
          {...props.register('gapBetweenEvents')}
          step={5}
          min={0}
          max={120}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox {...props.register('onlyConsecutiveReservations')} defaultChecked={props.watch('onlyConsecutiveReservations')} />
          }
          label="Разрешать запись только рядом с другими записями"
        />
      </Grid>
    </Grid>
  );
};

export default LimitSettingsEditor;
