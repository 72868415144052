import React from 'react';

import TelegramLoginButton from '../../components/telegramLoginButton/TelegramLoginButton';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../hook';
import { Navigate } from 'react-router-dom';
import VkLoginButton from '../../libs/vkLogin/VkLoginButton';
import { routes } from '../../routes';

interface LoginPageProps {
  // Define your props here
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <>
      {user?.accountId && <Navigate to={routes.admin.index} />}
      {user && !user.accountId && <Navigate to={routes.loginAccount} />}
      <Box
        sx={{ height: '100vh' }}
        className="flex justify-center items-center"
      >
        <Box sx={{ mt: '-150px' }} className="flex flex-col space-y-2">
          <Box
            component={'img'}
            src="/images/logo_only_blue.svg"
            style={{ height: '150px' }}
            alt="Logo"
            className="pb-4"
          />
          <div className="flex flex-col pb-4 items-center">
            <Typography variant="h4">Вход в Time2You</Typography>
            <Typography variant="body1">Войдите используя</Typography>
          </div>

          <div className="flex flex-col space-y-2 items-center w-full">
            <TelegramLoginButton />
            <Typography variant="body1">или</Typography>
            <VkLoginButton />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
