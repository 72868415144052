import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useAppSelector } from '../../../../../hook';
import useAccount from '../../../../../queryHelpers/queries/useAccount';
import useGoogleCalendars from '../../../../../queryHelpers/queries/useGoogleCalendars';
import AttachGoogleAccountButton from '../../../../../components/AttachGoogleAccountButton';

// Add this line at the top of your file to declare the google property on the Window interface

interface GoogleCalendarIntegrationViewEditProps {
  experienceId: string;
}

const GoogleCalendarIntegrationViewEdit: React.FC<
  GoogleCalendarIntegrationViewEditProps
> = ({ experienceId }) => {
  const user = useAppSelector((x) => x.auth.user);
  const account = useAccount(user?.accountId);
  const googleCalendars = useGoogleCalendars();

  // const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<string>(
    'use_availability_only',
  ); // Set default option
  const [enabledCalendars, setEnabledCalendars] = useState<{
    [key: string]: boolean;
  }>({});

  const handleToggleCalendar = (calendarId: string) => {
    setEnabledCalendars((prev) => ({
      ...prev,
      [calendarId]: !prev[calendarId], // Toggle the enabled state
    }));
    console.log(`Toggled calendar ${calendarId}`);
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value as string);
  };

  return (
    <div>
      <Typography variant="h6">Google Calendar Integration</Typography>
      {!account.data?.googleAccount && (
        <AttachGoogleAccountButton /> // Removed account prop as it's handled in the new component
      )}

      {googleCalendars.data && (
        <>
          <Typography variant="h6">Calendars</Typography>
          <List>
            {googleCalendars.data.map((calendar) => (
              <ListItem
                key={calendar.id}
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <ListItemText
                    primary={calendar.summary}
                    secondary={
                      calendar.description || 'No description available'
                    }
                  />
                  <Switch
                    checked={!!enabledCalendars[calendar.id!]}
                    onChange={() => handleToggleCalendar(calendar.id!)}
                  />
                </div>
                {/* Display FormControl on the next line if the calendar is enabled */}
                {enabledCalendars[calendar.id!] && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id={`event-options-label-${calendar.id}`}>
                      Event Options
                    </InputLabel>
                    <Select
                      labelId={`event-options-label-${calendar.id}`}
                      value={selectedOption}
                      onChange={(e) => handleOptionChange(e)}
                    >
                      <MenuItem value="create_events">
                        Also create events
                      </MenuItem>
                      <MenuItem value="create_events_no_customer">
                        Also create events without customer data
                      </MenuItem>
                      <MenuItem value="use_availability_only">
                        Use availability only
                      </MenuItem>{' '}
                      {/* New option added */}
                    </Select>
                  </FormControl>
                )}
              </ListItem>
            ))}
          </List>
        </>
      )}
    </div>
  );
};

export default GoogleCalendarIntegrationViewEdit;
