import React from 'react';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import * as yup from 'yup';
import { useEditForm } from '../../../../../hooks/useEditForm';
import useExperience from '../../../../../queryHelpers/queries/useExperience';
import useUpdateExperience from '../../../../../queryHelpers/mutations/useUpdateExperience';
import LimitSettingsEditor from './LimitSettingsEditor';
import CoffeeIcon from '@mui/icons-material/CoffeeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined';
import CompressIcon from '@mui/icons-material/CompressOutlined';
import Filter3Icon from '@mui/icons-material/Filter3Outlined';
import PendingActionsIcon from '@mui/icons-material/PendingActionsOutlined';
import {
  enabled,
  pluralBookings,
  pluralDays,
  pluralTime,
} from '../../../../../helpers/pluralHelpers';

type LimitSettingsViewEditProps = {
  experienceId: string;
};

export const LimitSettingsFormSchema = yup.object({}).required();

export type LimitSettingsFormInputs = {
  futureBookingDaysLimit: number | null;
  minimumNoticePeriod: number | null;
  dailyBookingLimit: number | null;
  gapBetweenEvents: number | null;
  onlyConsecutiveReservations: boolean;
};

const LimitSettingsViewEdit: React.FC<LimitSettingsViewEditProps> = (props) => {
  const experienceQuery = useExperience({ id: props.experienceId });
  const {
    form: {
      register,
      handleSubmit,
      watch,
      control,
      formState: { errors, isValid },
    },
    handleCancel,
  } = useEditForm<LimitSettingsFormInputs>({
    defaultValues: experienceQuery.data?.limitSettings,
    formSchema: LimitSettingsFormSchema,
  });

  const updateUserSettingsMutation = useUpdateExperience(props.experienceId);

  const onSubmit = (data: LimitSettingsFormInputs) => {
    updateUserSettingsMutation.mutate({
      limitSettings: data,
    });
  };

  return (
    <ViewEditControl
      title="Особые условия"
      editMode="inline"
      viewFields={[
        {
          icon: <DateRangeIcon />,
          label: 'На сколько дней вперед открыта запись',
          value:
            'На ' +
            pluralDays(
              experienceQuery.data?.limitSettings.futureBookingDaysLimit!,
            ),
        },
        {
          icon: <PendingActionsIcon />,
          label: 'За сколько времени можно записаться',
          value:
            'За ' +
            pluralTime(
              experienceQuery.data?.limitSettings.minimumNoticePeriod!,
            ),
        },
        {
          icon: <Filter3Icon />,
          label: 'Сколько всего записей в день',
          value: pluralBookings(
            experienceQuery.data?.limitSettings.dailyBookingLimit!,
          ),
        },
        {
          icon: <CoffeeIcon />,
          label: 'Какой перерыв между записями',
          value: Number(experienceQuery.data?.limitSettings.gapBetweenEvents)
            ? pluralTime(experienceQuery.data?.limitSettings.gapBetweenEvents!)
            : 'Без перерыва',
        },
        {
          icon: <CompressIcon />,
          label: 'Разрешать запись только рядом с другими записями',
          value: enabled(
            experienceQuery.data?.limitSettings.onlyConsecutiveReservations!,
          ),
        },
      ]}
      editor={
        <LimitSettingsEditor
          register={register}
          watch={watch}
          errors={errors}
          control={control}
        />
      }
      editorIsValid={isValid}
      onCancel={handleCancel}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default LimitSettingsViewEdit;
