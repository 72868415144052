import { addDays } from 'date-fns';
import { dateToDateString } from '../helpers/dateHelpers';
import { BookingVM } from '../viewModels/bookingViewModel';
import { Experiences } from './experiences';
import { Users } from './users';

const now = new Date();
const clientUser1 = Users.find((x) => x.id === 'clientUser1')!;
const clientUser2 = Users.find((x) => x.id === 'clientUser2')!;
const clientUser3 = Users.find((x) => x.id === 'clientUser3')!;
const experience1 = Experiences[0];
const experience2 = Experiences[1];

export const Bookings: BookingVM[] = [
  {
    id: 'booking1',
    createdIn: dateToDateString(addDays(now, -14)),
    date: dateToDateString(addDays(now, 0)),
    experienceId: experience1.id!,
    userId: clientUser1.id,
    timeSlot: { start: 600, end: 800 },
    name: clientUser1.name,
    phone: '1234567890',
    wishes: '',
  },
  {
    id: 'booking2',
    createdIn: dateToDateString(addDays(now, -14)),
    date: dateToDateString(addDays(now, 1)),
    experienceId: experience1.id!,
    userId: clientUser2.id,
    timeSlot: { start: 600, end: 800 },
    name: clientUser2.name,
    phone: '2345678901',
    wishes: 'Могу задержаться',
  },
  {
    id: 'booking3',
    createdIn: dateToDateString(addDays(now, -14)),
    date: dateToDateString(addDays(now, 8)),
    experienceId: experience2.id!,
    userId: clientUser3.id,
    timeSlot: { start: 600, end: 800 },
    name: clientUser3.name,
    phone: '2345678901',
    wishes: '',
  },
];
