import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import AppService from '../../services/appService';
import { bookingDbToVM } from '../mapping';

export default function useBooking(id: string | undefined) {
  return useQuery({
    queryKey: [queryKeys.bookings, id],
    queryFn: async () => {
      const service = new AppService();
      const booking = await service.getBookingById(id!);
      if (!booking) {
        return null;
      }

      return bookingDbToVM(booking);
    },
    select: (data) => {
      return data;
    },
  });
}
