import React from 'react';
import { Container, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { timeToString } from '../../helpers/timeHelpers';
import { format } from 'date-fns';
import useBooking from '../../queryHelpers/queries/useBooking';
import useExperience from '../../queryHelpers/queries/useExperience';

interface AppointmentConfirmationProps {}

const BookingConfirmationPage: React.FC<AppointmentConfirmationProps> = (
  props,
) => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const bookingQuery = useBooking(bookingId);
  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId as string,
    enabled: !!bookingQuery.data,
  });

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 2 }}>
        {bookingQuery.data && experienceQuery.data ? (
          <>
            <Typography variant="h6" gutterBottom>
              Поздравляем!
            </Typography>
            <Typography variant="body1">
              Вы записались на "{experienceQuery.data.summary.title}" на{' '}
              {format(new Date(bookingQuery.data.date), 'PPPP')} с{' '}
              {timeToString(bookingQuery.data.timeSlot.start)} до{' '}
              {timeToString(bookingQuery.data.timeSlot.end)}.
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Paper>
    </Container>
  );
};

export default BookingConfirmationPage;
