import { useQuery } from '@tanstack/react-query';
import { useGoogleClient, useGoogleUser } from '../../components/GoogleClientProvider';
import { calendar_v3 } from '@googleapis/calendar/v3';

export default function useGoogleCalendars() {
  const googleClient = useGoogleClient();
  const googleUser = useGoogleUser();

  return useQuery<calendar_v3.Schema$CalendarListEntry[]>({
    queryKey: ['googleCalendars'],
    enabled: !!googleUser,
    queryFn: async () => {
      const response = await googleClient?.calendar.calendarList.list();
      return (response as any).result.items; // Adjust as necessary based on the response structure
    },
    select: (result) => {
      return result;
    },
  });
}
