import React from 'react';
import EventIcon from '@mui/icons-material/EventOutlined';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import * as yup from 'yup';
import { useEditForm } from '../../../../../hooks/useEditForm';
import useBooking from '../../../../../queryHelpers/queries/useBooking';
import { timeToString } from '../../../../../helpers/timeHelpers';
import BookingScheduleEditor from './BookingScheduleEditor';
import useUpsertBooking from '../../../../../queryHelpers/mutations/useUpsertBooking';
import { BookingScheduleVM } from '../../../../../viewModels/bookingViewModel';
import { Nullable } from '../../../../../types';

type BookingScheduleViewEditProps = {
  bookingId: string;
};

const BookingScheduleFormSchema = yup
  .object({
    date: yup.string().required('Выберите дату'),
    timeSlot: yup.object().required('Выберите время'),
  })
  .required();

export type BookingScheduleFormInputs = Nullable<BookingScheduleVM>;

const BookingScheduleViewEdit: React.FC<BookingScheduleViewEditProps> = (
  props,
) => {
  const bookingQuery = useBooking(props.bookingId);
  const appForm = useEditForm<BookingScheduleFormInputs>({
    defaultValues: {
      date: bookingQuery.data?.date,
      timeSlot: bookingQuery.data?.timeSlot,
    },
    formSchema: BookingScheduleFormSchema,
  });

  const upsertBookingMutation = useUpsertBooking();

  const onSubmit = async (data: BookingScheduleFormInputs) => {
    const dataWithUndefined = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value === null ? undefined : value,
      ]),
    );

    upsertBookingMutation.mutate({
      ...bookingQuery.data!,
      ...dataWithUndefined,
      id: props.bookingId,
    });
  };

  return (
    <>
      <ViewEditControl
        title="Дата и время"
        viewFields={[
          {
            icon: <EventIcon />,
            label: 'Дата и время',
            value:
              bookingQuery.data?.date +
              ', ' +
              timeToString(bookingQuery.data!.timeSlot.start) +
              ' - ' +
              timeToString(bookingQuery.data!.timeSlot.end),
          },
        ]}
        editor={
          <BookingScheduleEditor
            experienceId={bookingQuery.data?.experienceId!}
            booking={bookingQuery.data!}
            form={appForm.form}
          />
        }
        editMode="inline"
        editorIsValid={appForm.form.formState.isValid}
        onCancel={appForm.handleCancel}
        onSave={appForm.form.handleSubmit(onSubmit)}
      />
    </>
  );
};

export default BookingScheduleViewEdit;
