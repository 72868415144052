import { DateCalendar } from '@mui/x-date-pickers';
import React from 'react';
import { AvailabilityViewModel } from '../../viewModels/availabilityViewModel';
import { isSameDay } from 'date-fns'; // Import isSameDay from date-fns
import { dateToDateString } from '../../helpers/dateHelpers';
import { DateString } from 'shared-ts/entities';

interface DayCalendarSelectorProps {
  availabilities: AvailabilityViewModel | undefined;
  value: DateString | undefined;
  onChange: (value: DateString | null) => void;
}

export const DayCalendarSelector: React.FC<DayCalendarSelectorProps> = (
  props,
) => {
  const onChange = (value: Date | null) => {
    if (!props.onChange) {
      return null;
    }

    if (!value) {
      props.onChange(null);
    }

    return props.onChange(dateToDateString(value!));
  };

  return (
    <>
      <DateCalendar
        disablePast
        onChange={onChange}
        value={props.value ? new Date(props.value) : null}
        showDaysOutsideCurrentMonth
        shouldDisableDate={(day: Date) => {
          return (
            props.availabilities?.days.find((x) =>
              isSameDay(new Date(x.date), day),
            ) === undefined
          );
        }}
      />
    </>
  );
};
