import React, { useEffect } from 'react';
import { Container } from '@mui/material';

import ViewDetailsSectionList from '../styled/ViewDetailsSectionList';
import UserSettingsViewEdit from './components/userSettings/UserSettingsViewEdit';
import SubscriptionViewEdit from './components/SubscriptionViewEdit';
import { useAdminOutletContext } from '../AdminLayout';
import { logout } from '../../../slicers/authSlice';
import { store } from '../../../store';
import { useAppSelector } from '../../../hook';
import { LoadingButton } from '@mui/lab';
import GoogleCalendarSettingsViewEdit from './components/userSettings/GoogleCalendarSettingsViewEdit';

interface SettingsPageProps {}

const SettingsPage: React.FC<SettingsPageProps> = () => {
  const outletContext = useAdminOutletContext();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: 'Настройки',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    store.dispatch(logout());
  };

  return (
    <Container>
      <ViewDetailsSectionList>
        <UserSettingsViewEdit userId={auth.user?.id!} />
        <SubscriptionViewEdit userId={auth.user?.id!} />
        <GoogleCalendarSettingsViewEdit userId={auth.user?.id!} />
        <LoadingButton loading={auth.pending} onClick={handleLogout}>
          Выйти
        </LoadingButton>
      </ViewDetailsSectionList>
    </Container>
  );
};

export default SettingsPage;
