import { useEffect, useState, useMemo } from 'react';
import { DayCalendarSelector } from '../../../../../components/bookingForm/DayCalendarSelector';
import TimeSlotSelector from '../../../../../components/bookingForm/TimeSlotSelector';
import { AvailabilityViewModel, DailyTimeSlotsViewModel } from '../../../../../viewModels/availabilityViewModel';
import useExperience from '../../../../../queryHelpers/queries/useExperience';
import { Box, FormHelperText } from '@mui/material';
import { Label } from '../../../styled/Label';
import { isEqual } from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';
import { BookingScheduleFormInputs } from './BookingScheduleViewEdit';
import { BookingVM } from '../../../../../viewModels/bookingViewModel';
import useAvailableTimeSlots from '../../../../../queryHelpers/queries/useAvailableTimeSlots';
import { format, startOfToday } from 'date-fns';
import { DateString } from 'shared-ts/entities';
import { getTimeSlotsForSelection } from '../../../../../helpers/timeslotsHelpers';

type Props = {
  experienceId: string;
  booking?: BookingVM;
  form: UseFormReturn<BookingScheduleFormInputs>;
};

const BookingScheduleEditor: React.FC<Props> = (props) => {
  const [dailyAvailabilities, setDailyAvailabilities] = useState<AvailabilityViewModel>({ days: [] });

  const experienceQuery = useExperience({
    id: props.experienceId,
    link: props.experienceId,
  });

  const date = props.form.watch('date');
  const availableTimeSlotsQuery = useAvailableTimeSlots(props.experienceId, date);

  const dailyAvailabilitiesData: AvailabilityViewModel = useMemo(() => {
    if (!availableTimeSlotsQuery.data || !experienceQuery.data) {
      return { days: [] };
    }

    const experienceDuration = experienceQuery.data.summary.duration!;

    const days: DailyTimeSlotsViewModel[] = availableTimeSlotsQuery.data.map(availability => ({
      date: availability.date,
      timeSlots: getTimeSlotsForSelection(availability.timeSlots.map(slot => ({
        start: slot.startTime,
        end: slot.endTime
      })), experienceDuration)
    }));

    return { days };
  }, [availableTimeSlotsQuery.data, experienceQuery.data]);

  useEffect(() => {
    if (!date) {
      props.form.setValue('date', format(startOfToday(), 'yyyy-MM-dd') as DateString);
    }
  }, [date, props.form, props.form.setValue]);

  useEffect(() => {
    if (dailyAvailabilitiesData.days.length > 0) {
      let updatedAvailabilities = { ...dailyAvailabilitiesData };

      if (props.booking) {
        // Add the booking date and time slot to the availability
        const dateAvailability = updatedAvailabilities.days.find(
          (x) => x.date === props.form.getValues('date'),
        );
        if (!dateAvailability) {
          updatedAvailabilities.days.push({
            date: props.booking.date,
            timeSlots: [props.booking.timeSlot],
          });
        } else if (
          dateAvailability.timeSlots.findIndex((x) =>
            isEqual(x, props.booking!.timeSlot),
          ) === -1
        ) {
          dateAvailability.timeSlots.push(props.booking.timeSlot);
        }

        props.form.setValue('date', props.booking.date);
        props.form.setValue('timeSlot', props.booking.timeSlot ?? null);
      } else {
        props.form.setValue('date', updatedAvailabilities.days[0]?.date ?? null);
        props.form.setValue('timeSlot', updatedAvailabilities.days[0]?.timeSlots[0] ?? null);
      }

      setDailyAvailabilities(updatedAvailabilities);
    }
  }, [props.booking, dailyAvailabilitiesData, props.form]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
      }}
    >
      <Box
        sx={{
          flex: 'auto',
          ml: -3,
        }}
      >
        <Controller
          name="date"
          control={props.form.control}
          render={({ field }) => (
            <DayCalendarSelector
              availabilities={dailyAvailabilities}
              value={field.value ?? undefined}
              onChange={(value) => {
                field.onChange(value);
                props.form.setValue('timeSlot', null, {
                  shouldValidate: true,
                });
              }}
            />
          )}
        />
        {props.form.formState.errors.date && (
          <FormHelperText error>
            {props.form.formState.errors.date?.message}
          </FormHelperText>
        )}
      </Box>

      <Box sx={{ flex: 'auto' }}>
        <Label>Время</Label>

        <Controller
          name="timeSlot"
          control={props.form.control}
          render={({ field }) => (
            <TimeSlotSelector
              slots={
                dailyAvailabilities?.days.find((x) => x.date === date)
                  ?.timeSlots || []
              }
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
        {props.form.formState.errors.timeSlot && (
          <FormHelperText error>
            {props.form.formState.errors.timeSlot?.message}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default BookingScheduleEditor;
