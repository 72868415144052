import { addDays, format } from 'date-fns';
import { ExperienceVM } from '../viewModels/experienceViewModel';
import { Accounts } from './accounts';
import { DateString } from 'shared-ts/entities';

const now = new Date();

export const Experiences: ExperienceVM[] = [
  {
    id: 'experience1',
    accountId: Accounts[0].id!,
    summary: {
      title: 'Прием к королю',
      description: 'Прием к королю, работа с подданными',
      link: 'king-appointment',
      duration: 60,
    },
    dayAvailability: [
      {
        weekDays: [0, 1, 2, 3, 4],
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        weekDays: [5],
        timeSlots: [
          { start: 600, end: 720 },
          { start: 760, end: 1080 },
        ],
      },
    ],
    dateAvailability: [
      {
        date: format(addDays(now, 5), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 900, end: 1080 }],
      },
      {
        date: format(addDays(now, 6), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        date: format(addDays(now, 7), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        date: format(addDays(now, 8), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        date: format(addDays(now, 15), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
    ],
    limitSettings: {
      futureBookingDaysLimit: 30,
      minimumNoticePeriod: 10,
      dailyBookingLimit: 3,
      gapBetweenEvents: 10,
      onlyConsecutiveReservations: true,
    },
  },
  {
    id: 'experience2',
    accountId: Accounts[0].id!,
    summary: {
      title: 'Консультация по поводу зимы',
      description:
        'Консультация по поводу зимы, подготовка к холодам, выбор одежды и обуви, выбор транспорта, выбор места отдыха',
      link: 'winter-consulting',
      duration: 60,
    },
    dayAvailability: [
      {
        weekDays: [0, 1, 2, 3, 4],
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        weekDays: [5],
        timeSlots: [
          { start: 600, end: 720 },
          { start: 760, end: 1080 },
        ],
      },
    ],
    dateAvailability: [
      {
        date: format(addDays(now, 2), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 900, end: 1080 }],
      },
      {
        date: format(addDays(now, 3), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        date: format(addDays(now, 4), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        date: format(addDays(now, 9), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
      {
        date: format(addDays(now, 11), 'yyyy-MM-dd') as DateString,
        timeSlots: [{ start: 600, end: 1080 }],
      },
    ],
    limitSettings: {
      futureBookingDaysLimit: 30,
      minimumNoticePeriod: 60,
      dailyBookingLimit: 1,
      gapBetweenEvents: 0,
      onlyConsecutiveReservations: false,
    },
  },
];
