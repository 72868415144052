import { ExperienceVM } from './experienceViewModel';

export function createNewExperienceVM(): ExperienceVM {
  return {
    id: undefined,
    accountId: 'account_id_placeholder',
    summary: {
      title: '',
      description: '',
      link: '',
      duration: 0,
    },
    dayAvailability: [],
    dateAvailability: [],
    limitSettings: {
      futureBookingDaysLimit: 30,
      minimumNoticePeriod: 60,
      dailyBookingLimit: 10,
      gapBetweenEvents: 15,
      onlyConsecutiveReservations: false,
    },
  };
}
