import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { DeleteExperienceRequest } from 'shared-ts/functions/deleteExperience.dto';
import { DeleteExperienceFunc } from '../../firebaseFunctions';

function useDeleteExperience(props: { id: string }) {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, Error, void, unknown>({
    mutationFn: async () => {
      const updateRequest: DeleteExperienceRequest = {
        id: props.id,
      };
      await DeleteExperienceFunc(updateRequest);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.experiences],
      });
    },
  });

  return mutation;
}

export default useDeleteExperience;
