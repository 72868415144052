import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { GetAvailableTimeSlotsFunc } from '../../firebaseFunctions';
import { DateAvailability } from 'shared-ts/entities';
import { format, startOfToday } from 'date-fns';

export default function useAvailableTimeSlots(experienceId: string, date: string | null) {
  return useQuery<DateAvailability[]>({
    queryKey: [queryKeys.experiences, 'availableTimeSlots', experienceId, date],
    queryFn: async () => {
      if (date === null) {
        date = format(startOfToday(), 'yyyy-MM-dd');
      }
      const result = await GetAvailableTimeSlotsFunc({ experienceId, date });
      return result.data.availabilities;
    },
    // Enable the query only if both experienceId and date are provided
    enabled: !!experienceId && !!date,
  });
}
