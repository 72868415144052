import { format, parse } from 'date-fns';
import { DateString } from 'shared-ts/entities';

export const dateToDateString = (date: Date): DateString => {
  return format(date, 'yyyy-MM-dd') as DateString;
};

export const dateStringToDate = (dateString: DateString): Date => {
  return parse(dateString, 'yyyy-MM-dd', new Date());
};
