import {
  AppBar,
  BottomNavigation,
  Box,
  Fab,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import MenuNavigationButton from './components/MenuNavigationButton';
import BackNavigationButton from './components/BackNavigationButton';

export type TopAppBarActionItem = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export type TopAppBarProps = {
  title: string;
  backButtonPath?: string;
  actionItems?: TopAppBarActionItem[];
};

export type BottomAppBarFab = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type BottomAppBarProps = {
  fab?: BottomAppBarFab;
};

export type AppBarsProps = {
  TopAppBar?: TopAppBarProps;
  BottomAppBar?: BottomAppBarProps;
};

export type AdminOutletContext = {
  setAppBars: (props: AppBarsProps) => void;
};

export function useAdminOutletContext() {
  return useOutletContext<AdminOutletContext>();
}

export default function AdminLayout() {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      console.log(`Container size: ${offsetWidth} x ${offsetHeight}`);
    }
  }, []);

  const [appBarsProps, setAppBarProps] = useState<AppBarsProps | null>(null);

  const setAppBars = (props: AppBarsProps) => {
    setAppBarProps(props);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" variant="elevation" elevation={1}>
        <Toolbar>
          <Box sx={{ mr: 2 }}>
            {appBarsProps?.TopAppBar?.backButtonPath ? (
              <BackNavigationButton
                path={appBarsProps?.TopAppBar?.backButtonPath}
              />
            ) : (
              <MenuNavigationButton />
            )}
          </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {appBarsProps?.TopAppBar?.title}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            {appBarsProps?.TopAppBar?.actionItems?.map((item, index) => (
              <Box key={index} sx={{ ml: 1 }}>
                <IconButton
                  color="inherit"
                  onClick={item.onClick}
                  title={item.label}
                >
                  {item.icon}
                </IconButton>
              </Box>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ mt: 10, mb: 10, width: 1 }}>
        <Box component="main">
          <Outlet
            context={
              {
                setAppBars,
              } satisfies AdminOutletContext
            }
          />
        </Box>
      </Box>

      {appBarsProps?.BottomAppBar?.fab && (
        <BottomNavigation
          style={{ backgroundColor: 'transparent' }}
          sx={{
            position: 'fixed',
            bottom: 0,
            width: 1,
          }}
        >
          <Box sx={{ position: 'absolute', bottom: 20 }}>
            <Fab
              color="primary"
              onClick={appBarsProps?.BottomAppBar.fab.onClick}
              title={appBarsProps?.BottomAppBar.fab.title}
            >
              {appBarsProps?.BottomAppBar.fab.icon}
            </Fab>
          </Box>
        </BottomNavigation>
      )}

      {/* <BottomNav /> */}
    </Box>
  );
}
