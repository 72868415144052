import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TelegramAuthFunc, VkAuthFunc } from '../firebaseFunctions';
import {
  User as FirebaseUser,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { User } from '../../../shared-ts/entities';
import { AuthVkRequest } from 'shared-ts/functions/authVk.dto';
import { TelegramUser } from 'shared-ts/telegram.types';
import AppService from '../services/appService';

async function getUser(
  firebaseUser: FirebaseUser | null,
): Promise<User | null> {
  if (!firebaseUser) {
    return null;
  }

  const appService = new AppService();
  const user = await appService.getUserById(firebaseUser.uid);
  if (!user) {
    return null;
  }
  return user;
}

export const actualizeStatus = createAsyncThunk<User | null, void>(
  'user/actualizeStatus',
  async () => {
    return await getUser(auth.currentUser);
  },
);

export const login = createAsyncThunk<User | null, TelegramUser>(
  'user/login',
  async (telegramUser) => {
    const customTokenRs = await TelegramAuthFunc(telegramUser);
    const authData = await signInWithCustomToken(
      auth,
      customTokenRs.data as string,
    );
    return await getUser(authData.user);
  },
);

export const loginVk = createAsyncThunk<User | null, AuthVkRequest>(
  'user/vklogin',
  async (data) => {
    const customTokenRs = await VkAuthFunc(data);
    const authData = await signInWithCustomToken(
      auth,
      customTokenRs.data as string,
    );
    return await getUser(authData.user);
  },
);

export const logout = createAsyncThunk('user/logout', async () => {
  await signOut(auth);
});

interface AuthSliceData {
  user: User | null;
  pending: boolean;
  error: string | null;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, error: null, pending: false } as AuthSliceData,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actualizeStatus.fulfilled, (state, action) => {
      state.user = action.payload;
      state.error = null;
    });

    builder.addCase(loginVk.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(loginVk.fulfilled, (state, action) => {
      state.pending = false;
      state.user = action.payload;
      state.error = null;
    });
    builder.addCase(loginVk.rejected, (state, action) => {
      state.pending = true;
      state.error = 'Error';
    });

    builder.addCase(login.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.pending = false;
      state.user = action.payload;
      state.error = null;
    });
    builder.addCase(logout.pending, (state, action) => {
      state.pending = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.pending = false;
      state.user = null;
      state.error = null;
    });
  },
});

export default authSlice.reducer;
