import React, { useState, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import { Box, ListItemButton, SxProps, Theme } from '@mui/material';
import { timeToString } from '../../../../../helpers/timeHelpers';
import { Times } from './TimeSelector.helpers';

interface TimeSelectorProps {
  sx?: SxProps<Theme>;
  value?: number;
  minTime?: number;
  onChange?: (value: number) => void;
}

const TimeSelector: React.FC<TimeSelectorProps> = ({
  sx,
  value,
  minTime = 0,
  onChange,
}) => {
  const [selectedTime, setSelectedTime] = useState<number | null>(
    value || null,
  );
  const selectedTimeRef = useRef<HTMLDivElement>(null);
  const prevValueRef = useRef(value);

  useEffect(() => {
    if (prevValueRef.current !== value) {
      setSelectedTime(value!);
      prevValueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    if (selectedTimeRef.current) {
      selectedTimeRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, []);

  useEffect(() => {
    if (selectedTimeRef.current) {
      selectedTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedTime]);

  const handleListItemClick = (time: number) => {
    setSelectedTime(time);
    if (onChange) {
      onChange(time);
    }
  };

  return (
    <Box sx={{ ...sx }}>
      <Box
        sx={{
          maxHeight: '150px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '3px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        <List>
          {Times.map((time) => (
            <ListItemButton
              key={time}
              selected={selectedTime === time}
              onClick={() => handleListItemClick(time)}
              ref={selectedTime === time ? selectedTimeRef : null}
              disabled={time < minTime}
            >
              {timeToString(time)}
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default TimeSelector;
