import { Typography } from '@mui/material';
import logo from '../../../assets/logo_only_blue.svg';

const HeroSection = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center pt-12 gap-2">
      <img src={logo} alt="logo" width={100} />
      <Typography variant="h3">Time2You</Typography>
      <Typography variant="h6">
        Онлайн запись для Ваших клиентов
      </Typography>
      <Typography variant="caption" maxWidth={400} className='text-center'>
        Настраивайте свои услуги, расписание и открывайте онлайн запись для своих клиентов
      </Typography>
    </div>
  );
};

export default HeroSection;
