import React, { useState } from 'react';
import { WeekDayAvailabilityFormInputs } from './DateAvailabilityViewEdit';
import { UseFormRegister, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { DayCalendarSelector } from './DayCalendarSelector';
import TimeSlotsEditor from './TimeSlotsEditor';
import { Label } from '../../../styled/Label';
import { DateAvailabilityVM } from '../../../../../viewModels/experienceViewModel';
import { TimeSlotVM } from '../../../../../viewModels/simpleTypes';
import { DateString } from 'shared-ts/entities';

interface DateAvailabilityEditProps {
  register: UseFormRegister<WeekDayAvailabilityFormInputs>;
  trigger: UseFormTrigger<WeekDayAvailabilityFormInputs>;
  watch: UseFormWatch<WeekDayAvailabilityFormInputs>;
}

interface DateAvailabilityEditorProps {
  edit?: DateAvailabilityEditProps;
  value?: DateAvailabilityVM[];
  defaultDate?: DateString | null;
  onSelectedDateChange?: (date: DateString | null) => void;
}

const DateAvailabilityEditor: React.FC<DateAvailabilityEditorProps> = (
  props,
) => {
  const dateAvails = props.edit?.watch('dateAvailabilities') ?? props.value!;
  const [selectedDate, setSelectedDate] = useState<DateAvailabilityVM | null>(
    () => {
      if (props.defaultDate) {
        return dateAvails.find((d) => d.date === props.defaultDate) ?? null;
      }
      return null;
    },
  );

  const handleDateChange = (date: DateAvailabilityVM | null) => {
    setSelectedDate(date);
    props.onSelectedDateChange?.(date?.date ?? null);
  };

  const handleSlotsChange = (slots: TimeSlotVM[]) => {
    selectedDate!.timeSlots = slots;

    if (!dateAvails.find((d) => d.date === selectedDate?.date)) {
      dateAvails.push(selectedDate!);
    }

    props.edit?.trigger('dateAvailabilities');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
      }}
    >
      <Box
        sx={{
          flex: 'auto',
          ml: -3,
        }}
      >
        <DayCalendarSelector
          onChange={handleDateChange}
          dates={dateAvails}
          defaultDate={props.defaultDate}
        />
      </Box>
      <Box sx={{ flex: 'auto' }}>
        <Label>Время</Label>
        <TimeSlotsEditor
          slots={selectedDate?.timeSlots ?? []}
          onChange={props.edit && handleSlotsChange}
        />
      </Box>
    </Box>
  );
};

export default DateAvailabilityEditor;
