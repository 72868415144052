import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { UserVM } from '../../viewModels/userViewModel';
import { UpdateUserFunc } from '../../firebaseFunctions';
import { userVMToDb } from '../mapping';

export default function useUpdateUserSettings(userId: string) {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, Error, Partial<UserVM>, unknown>({
    mutationFn: async (data: Partial<UserVM>) => {
      const updateRequest = {
        ...userVMToDb(data),
        id: userId,
      };

      await UpdateUserFunc(updateRequest);
    },

    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users],
      }),
  });

  return mutation;
}
