import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import React, { useEffect } from 'react';
import { DateAvailabilityVM } from '../../../../../viewModels/experienceViewModel';
import {
  dateStringToDate,
  dateToDateString,
} from '../../../../../helpers/dateHelpers';
import { Badge } from '@mui/material';
import { DateString } from 'shared-ts/entities';

interface DayCalendarSelectorProps {
  onChange: (date: DateAvailabilityVM | null) => void;
  dates: DateAvailabilityVM[];
  defaultDate?: DateString | null;
}

function ServerDay(
  props: PickersDayProps<Date> & { dateAvailabilities?: DateAvailabilityVM[] },
) {
  const { dateAvailabilities, day, outsideCurrentMonth, ...other } = props;

  const currentDateString = dateToDateString(day);
  const selectedDate = props.dateAvailabilities?.find(
    (x) => x.date === currentDateString,
  );

  return (
    <Badge
      key={props.day.toString()}
      sx={{ zIndex: 0 }}
      overlap="circular"
      color="secondary"
      badgeContent={selectedDate?.timeSlots.length}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export const DayCalendarSelector: React.FC<DayCalendarSelectorProps> = (
  props,
) => {
  const [selectedDate, setSelectedDate] =
    React.useState<DateAvailabilityVM | null>(() => {
      if (props.defaultDate) {
        let selectedDayAvailability = props.dates.find(
          (d) => d.date === props.defaultDate,
        );
        if (selectedDayAvailability === undefined) {
          selectedDayAvailability = {
            date: props.defaultDate,
            timeSlots: [],
          } as DateAvailabilityVM;
        }

        return selectedDayAvailability;
      }

      const now = dateToDateString(new Date());
      let nowDayAvailability = props.dates.find((d) => d.date === now);
      if (nowDayAvailability === undefined) {
        nowDayAvailability = {
          date: now,
          timeSlots: [],
        } as DateAvailabilityVM;
      }
      return nowDayAvailability;
    });

  useEffect(() => {
    handleDateChange(selectedDate!.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (date: DateString | null) => {
    let selectedDate = props.dates.find((d) => d.date === date);
    if (selectedDate === undefined) {
      selectedDate = {
        date: date!,
        timeSlots: [],
      } as DateAvailabilityVM;
    }
    setSelectedDate(selectedDate);
    props.onChange(selectedDate);
  };

  return (
    <>
      <DateCalendar
        disablePast
        showDaysOutsideCurrentMonth
        value={dateStringToDate(selectedDate!.date)}
        onChange={(date: Date | null) =>
          date === null ? null : handleDateChange(dateToDateString(date))
        }
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            dateAvailabilities: props.dates,
          } as any,
        }}
      />
    </>
  );
};
