import { addDays, format } from 'date-fns';
import { AccountVM } from '../viewModels/userViewModel';
import { DateString } from 'shared-ts/entities';

const now = new Date();

export const Accounts: AccountVM[] = [
  {
    id: 'account1',
    balance: 120,
    nextPayment: format(addDays(now, 20), 'yyyy-MM-dd') as DateString,
    paidAccessUntil: format(addDays(now, 21), 'yyyy-MM-dd') as DateString,
  },
];
