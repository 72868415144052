import React from 'react';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import * as yup from 'yup';
import { useEditForm } from '../../../../../hooks/useEditForm';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import TimelapseIcon from '@mui/icons-material/TimelapseOutlined';
import LinkIcon from '@mui/icons-material/LinkOutlined';
import useExperience from '../../../../../queryHelpers/queries/useExperience';
import useUpdateExperience from '../../../../../queryHelpers/mutations/useUpdateExperience';
import { getBookLink } from '../../../../../helpers/linkHelpers';
import { pluralTime } from '../../../../../helpers/pluralHelpers';
import SummaryEditor from './SummaryEditor';
import { Link } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/WorkOutline';
import { SummaryVM } from '../../../../../viewModels/experienceViewModel';

type SummaryViewEditProps = {
  experienceId: string;
};

export const SummaryFormSchema = (showLink: boolean = true) =>
  yup
    .object({
      title: yup.string().required('Укажите название'),
      description: yup.string(),
      duration: yup.number().required('Укажите продолжительность'),
      link: showLink
        ? yup
            .string()
            .required('Укажите ссылку')
            .max(25, 'Укажаите короче ссылку')
            .matches(
              /^[a-zA-Z0-9-]+$/,
              'Используйте только латинские буквы и цифры',
            )
        : yup.string(),
    })
    .required();

export type SummaryFormInputs = SummaryVM;

const SummaryViewEdit: React.FC<SummaryViewEditProps> = (props) => {
  const experienceQuery = useExperience({ id: props.experienceId });
  const {
    form: {
      register,
      handleSubmit,
      watch,
      formState: { errors, isValid },
    },
    handleCancel,
  } = useEditForm<SummaryFormInputs>({
    defaultValues: experienceQuery.data?.summary,
    formSchema: SummaryFormSchema(),
  });

  const updateUserSettingsMutation = useUpdateExperience(props.experienceId);

  const onSubmit = (data: SummaryFormInputs) => {
    updateUserSettingsMutation.mutate({
      summary: {
        title: data.title,
        description: data.description ?? null,
        duration: data.duration,
        link: data.link,
      },
    });
  };

  return (
    <ViewEditControl
      title="Услуга"
      editMode="inline"
      viewFields={[
        {
          icon: <WorkIcon />,
          label: 'Название',
          value: experienceQuery.data?.summary.title || '',
        },
        {
          icon: <DescriptionIcon />,
          label: 'Описание',
          value: experienceQuery.data?.summary.description || '',
        },
        {
          icon: <TimelapseIcon />,
          label: 'Продолжительность',
          value: !!experienceQuery.data
            ? pluralTime(experienceQuery.data.summary.duration!)
            : '',
        },
        {
          icon: <LinkIcon />,
          label: 'Ссылка для клиента',
          value: (
            experienceQuery.data && (
              <Link to={getBookLink(experienceQuery.data)}>
                {getBookLink(experienceQuery.data)}
              </Link>
            )
          ),
        },
      ]}
      editor={
        <SummaryEditor watch={watch} register={register} errors={errors} />
      }
      editorIsValid={isValid}
      onCancel={handleCancel}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default SummaryViewEdit;
