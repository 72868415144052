import React from 'react';
import { TextField, Grid, FormHelperText } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { UserVM } from '../../../../../viewModels/userViewModel';
import { Label } from '../../../styled/Label';
import { UserSettingsFormInputs } from './UserSettingsViewEdit';

interface UserSettingEditorProps {
  user: UserVM;
  register: UseFormRegister<UserSettingsFormInputs>;
  errors: FieldErrors<UserSettingsFormInputs>;
}

const UserSettingEditor: React.FC<UserSettingEditorProps> = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>Имя</Label>
        <TextField
          size="small"
          error={!!props.errors.name}
          {...props.register('name')}
          fullWidth
        />
        <FormHelperText error>{props.errors.name?.message}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>Email</Label>
        <TextField
          size="small"
          error={!!props.errors.email}
          {...props.register('email')}
          fullWidth
        />
        <FormHelperText error>{props.errors.email?.message}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default UserSettingEditor;
