import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { BookingVM } from '../../viewModels/bookingViewModel';
import { CreateBookingFunc, UpdateBookingFunc } from '../../firebaseFunctions';
import { bookingVMToDb } from '../mapping';
import { Booking } from 'shared-ts/entities';

export default function useUpsertBooking() {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, Error, Partial<BookingVM>, unknown>({
    mutationFn: async (data: Partial<BookingVM>) => {
      const booking: Partial<Booking> = bookingVMToDb(data);

      if (booking.id) {
        // Update existing booking
        const updateBooking = { ...booking, id: booking.id };

        if (!updateBooking.startTime) {
          delete updateBooking.startTime;
        }
        if (!updateBooking.endTime) {
          delete updateBooking.endTime;
        }
        if (!updateBooking.date) {
          delete updateBooking.date;
        }
        if (!updateBooking.userId) {
          delete updateBooking.userId;
        }
        if (!updateBooking.experienceId) {
          delete updateBooking.experienceId;
        }

        await UpdateBookingFunc(updateBooking);
        return booking.id;
      } else {
        // Create new booking
        const result = await CreateBookingFunc({
          ...booking,
          experienceId: booking.experienceId!,
          date: booking.date!,
          startTime: booking.startTime!,
          endTime: booking.endTime!,
          name: booking.name!,
          phone: booking.phone!,
          wishes: booking.wishes,
        });
        return result.data?.id;
      }
    },

    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.bookings],
      });

      queryClient.invalidateQueries({
        queryKey: [queryKeys.experiences, 'availableTimeSlots'],
      });
    },
  });

  return mutation;
}
