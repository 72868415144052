import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import AppService from '../../services/appService';

type Data = {
  userId: string;
};

export default function useCreateAccount() {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, Error, Data, unknown>({
    mutationFn: async (data: Data) => {
      const appService = new AppService();
      await appService.createAccount(data.userId);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users],
        exact: false,
      });

      queryClient.invalidateQueries({
        queryKey: [queryKeys.accounts],
        exact: false,
      });
    },
  });

  return mutation;
}
