import React from 'react';
import { Button } from '@mui/material';
import { useGoogleClient } from './GoogleClientProvider';
import useSetGoogleIntegration from '../queryHelpers/mutations/useSetGoogleIntegration';
import { useAppSelector } from '../hook';
import useAccount from '../queryHelpers/queries/useAccount';

interface AttachGoogleAccountButtonProps {
  // Removed account prop
}

const AttachGoogleAccountButton: React.FC<
  AttachGoogleAccountButtonProps
> = () => {
  const googleClient = useGoogleClient();
  const useSetGoogleIntegrationMutation = useSetGoogleIntegration();
  const user = useAppSelector((x) => x.auth.user); // Get user from state
  const account = useAccount(user?.accountId);

  const handleAttachCalendar = async () => {
    try {
      const offlineAccessResponse =
        await googleClient?.auth?.grantOfflineAccess();
      if (!offlineAccessResponse) {
        throw new Error('Could not get offline access');
      }

      await useSetGoogleIntegrationMutation.mutateAsync({
        accountId: user?.accountId!,
        code: offlineAccessResponse.code,
      });
    } catch (error) {
      console.error('Failed to attach Google Calendar', error);
    }
  };

  return (
    <>
      {!account.data?.googleAccount && (
        <Button onClick={handleAttachCalendar}>Attach Google Calendars</Button>
      )}
    </>
  );
};

export default AttachGoogleAccountButton;
