import React from 'react';
import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material';
import { timeToString } from '../../helpers/timeHelpers';
import _ from 'lodash';
import { TimeSlotVM } from '../../viewModels/simpleTypes';

interface TimeSlotSelectorProps {
  slots: TimeSlotVM[];
  onChange: (slot: TimeSlotVM) => void;
  value: TimeSlotVM | null;
  helperText?: string;
  error?: boolean;
}

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = (props) => {
  const handleClick = (slot: TimeSlotVM) => {
    if (!props.onChange) {
      return;
    }
    props.onChange(slot);
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
        {props.slots.map((slot) => (
          <Button
            key={slot.start}
            disableElevation
            variant={_.isEqual(props.value, slot) ? 'contained' : 'text'}
            onClick={() => handleClick(slot)}
          >
            {timeToString(slot.start)}
            {_.isEqual(props.value, slot) ? ' - ' + timeToString(slot.end) : ''}
          </Button>
        ))}
      </Stack>
      {props.slots.length === 0 && (
        <Typography variant="caption">
          Нет доступных временных слотов
        </Typography>
      )}
      {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )}
    </Box>
  );
};

export default TimeSlotSelector;
