import React from 'react';
import ViewEditControl from '../../components/viewEditControl/ViewEditControl';
import useUser from '../../../../queryHelpers/queries/useUser';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined';
import AddBalanceIcon from '@mui/icons-material/AddCardOutlined';
import { pluralCredits } from '../../../../helpers/pluralHelpers';
import { format } from 'date-fns';
import { dateStringToDate } from '../../../../helpers/dateHelpers';
import useAccount from '../../../../queryHelpers/queries/useAccount';
import config from '../../../../config';
import { DateString } from 'shared-ts/entities';

type SubscriptionViewEditProps = {
  userId: string;
};

const SubscriptionViewEdit: React.FC<SubscriptionViewEditProps> = (props) => {
  const userQuery = useUser(props.userId);
  const accountQuery = useAccount(userQuery.data?.accountId);
  const account = accountQuery.data;

  return (
    <ViewEditControl
      title="Подписка"
      editMode='inline'
      viewEditIcon={<AddBalanceIcon />}
      viewFields={[
        {
          icon: <AccessTimeIcon />,
          label: 'Следующее списание',
          value:
            account?.paidAccessUntil &&
            pluralCredits(config.subscriptionCost) +
              ', ' +
              format(
                dateStringToDate(account?.paidAccessUntil as DateString),
                'dd MMMM',
              ),
        },
        {
          icon: <AccountBalanceIcon />,
          label: 'Баланс',
          value: pluralCredits(account?.balance || 0),
        },
      ]}
      editor={null}
      onCancel={() => {}}
      onSave={async () => {}}
    />
  );
};

export default SubscriptionViewEdit;
