import { Box, Card, CardContent, Typography } from '@mui/material';
import ImgHowToStep1 from '../../../assets/landing/HowToStep1.png';
import ImgHowToStep2 from '../../../assets/landing/HowToStep2.png';
import ImgHowToStep3 from '../../../assets/landing/HowToStep3.png';
import ImgHowToStep4 from '../../../assets/landing/HowToStep4.png';
import ImgHowToStep5 from '../../../assets/landing/HowToStep5.png';

type ImagePosition = 'left' | 'right';

type HowToUseSectionItemProps = {
  title: string;
  description: string;
  image: string;
  imagePosition: ImagePosition;
};

const HowToUseSectionItem: React.FC<HowToUseSectionItemProps> = (props) => {
  const flexDirection =
    props.imagePosition === 'right' ? 'md:flex-row' : 'md:flex-row-reverse';
  return (
    <Card elevation={0} sx={{ maxWidth: 800 }}>
      <CardContent>
        <div
          className={
            'flex flex-col items-center text-center gap-4 md:gap-11 ' +
            flexDirection
          }
        >
          <div className="flex-grow md:text-left sm:text-center">
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color="primary"
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </div>
          <div>
            <Box
              sx={{ width: 150 }}
              className="flex items-center flex-grow-0 justify-center"
            >
              <img
                src={props.image}
                alt="logo"
                width={'100'}
                className="transform rounded"
              />
            </Box>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const HowToUseSection = () => {
  const items: Omit<HowToUseSectionItemProps, 'imagePosition'>[] = [
    {
      title: '1. Зарегистрируйтесь',
      description: 'Используйте Telegram and VK аккаунт',
      image: ImgHowToStep1,
    },
    {
      title: '2. Создайте услугу',
      description: 'Укажите свое расписание. Укажите какие условия записи.',
      image: ImgHowToStep2,
    },
    {
      title: '3. Поделитесь ссылкой для онлайн-записи',
      description: `Скопируйте и вставьте ссылку в Вашем профиле, или поделитесь ей удобным Вам способом.

Вы также можете распечатать QR-код и повесить на дверь.`,
      image: ImgHowToStep3,
    },
    {
      title: '4. Получите уведомление об онлайн-записи',
      description:
        'Как только Ваш клиент запишется, Вы получите уведомление об онлайн-записи.',
      image: ImgHowToStep4,
    },
    {
      title: '5. Посмотрите все онлай-записи',
      description:
        'Войдите в систему, посмотрите все предстоящие и прошедшие онлайн-записи. При желании, Вы можете их корректировать.',
      image: ImgHowToStep5,
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">
        Откройте онлайн запись за {items.length} шагов
      </Typography>
      <div className="flex flex-col justify-center gap-6">
        {items.map((item, index) => (
          <HowToUseSectionItem
            key={item.title}
            title={item.title}
            description={item.description}
            image={item.image}
            imagePosition={index % 2 === 0 ? 'right' : 'left'}
          />
        ))}
      </div>
    </div>
  );
};

export default HowToUseSection;
