import { Booking } from 'shared-ts/entities';
import { BookingVM } from '../viewModels/bookingViewModel';
import { Experience } from 'shared-ts/entities';
import { ExperienceVM } from '../viewModels/experienceViewModel';
import { UserVM } from '../viewModels/userViewModel';
import { User } from 'shared-ts/entities';

export function bookingDbToVM(booking: Booking): BookingVM {
  return {
    id: booking.id,
    experienceId: booking.experienceId,
    date: booking.date,
    // createdIn: booking.createdAt,
    name: booking.name,
    phone: booking.phone,
    timeSlot: {
      start: booking.startTime,
      end: booking.endTime,
    },
    userId: booking.userId,
    wishes: booking.wishes,
  } as BookingVM;
}

export function experienceDbToVM(experience: Experience): ExperienceVM {
  return {
    id: experience.id,
    accountId: experience.accountId,
    dateAvailability: experience.dateAvailability.map((x) => ({
      date: x.date,
      timeSlots: x.timeSlots.map((y) => ({
        start: y.startTime,
        end: y.endTime,
      })),
    })),
    dayAvailability: [],
    limitSettings: experience.limitSettings,
    summary: {
      title: experience.title,
      description: experience.description,
      duration: experience.duration,
      link: experience.link,
    },
  } as ExperienceVM;
}

export function experienceVMToDb(
  experienceVM: Partial<ExperienceVM>,
): Experience {
  return {
    id: experienceVM.id,
    accountId: experienceVM.accountId,
    dateAvailability: experienceVM.dateAvailability?.map((x) => ({
      date: x.date,
      timeSlots: x.timeSlots.map((y) => ({
        startTime: y.start,
        endTime: y.end,
      })),
    })),
    dayAvailability: [], // Assuming this is handled elsewhere or remains empty
    limitSettings: experienceVM.limitSettings,
    title: experienceVM.summary?.title,
    description: experienceVM.summary?.description,
    duration: experienceVM.summary?.duration,
    link: experienceVM.summary?.link,
  } as Experience;
}

export const userVMToDb = (user: Partial<UserVM>): Partial<User> => {
  return {
    name: user.name,
    email: user.email,
  };
};

export const bookingVMToDb = (booking: Partial<BookingVM>): Partial<Booking> => {
  return {
    id: booking.id,
    experienceId: booking.experienceId,
    date: booking.date,
    name: booking.name,
    phone: booking.phone,
    startTime: booking.timeSlot?.start,
    endTime: booking.timeSlot?.end,
    userId: booking.userId,
    wishes: booking.wishes,
  };
};
