import { useEffect, useId } from 'react';
import config from '../../config';
import { login } from '../../slicers/authSlice';
import { useAppSelector } from '../../hook';
import { CircularProgress } from '@mui/material';
import { store } from '../../store';
import { TelegramUser } from 'shared-ts/telegram.types';

// TODO: Finalize security https://core.telegram.org/widgets/login#checking-authorization
window.onTelegramAuth = async (user: TelegramUser) => {
  await store.dispatch(login(user));
};

export default function TelegramLoginButton() {
  const auth = useAppSelector((x) => x.auth);
  const id = useId();

  useEffect(() => {
    const element = document.getElementById(`TelegramLogin_${id}`);
    if (auth.user) {
      if (element) {
        element.innerHTML = '';
      }
    } else {
      const loginBtn = document.createElement('script');
      loginBtn.async = true;
      loginBtn.src = 'https://telegram.org/js/telegram-widget.js?22';
      loginBtn.setAttribute('data-telegram-login', config.telegramBot);
      loginBtn.setAttribute('data-size', 'large');
      loginBtn.setAttribute('data-radius', '8');
      loginBtn.setAttribute('data-onauth', 'onTelegramAuth(user)');
      loginBtn.setAttribute('data-request-access', 'write');
      element?.appendChild(loginBtn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return (
    <>
      {auth.pending ? <CircularProgress /> : <></>}
      <div
        style={{ display: auth.pending ? 'none' : 'block' }}
        id={'TelegramLogin_' + id}
      ></div>
    </>
  );
}
