import { TimeSlotVM } from '../viewModels/simpleTypes';

export const getTimeSlotsForSelection = (timeSlots: TimeSlotVM[], duration: number): TimeSlotVM[] => {
  const splitSlots: TimeSlotVM[] = [];
  timeSlots.forEach(slot => {
    let start = slot.start;
    while (start + duration <= slot.end) {
      splitSlots.push({ start, end: start + duration });
      start += duration;
    }
  });
  return splitSlots;
};
