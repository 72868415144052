import { UserVM } from '../viewModels/userViewModel';
import { Accounts } from './accounts';

export const Users: UserVM[] = [
  {
    id: 'user1',
    accountId: Accounts[0].id!,
    name: 'Джон Сноу',
    email: 'jon.snow@example.org',
    telegramId: 1234,
  },
  {
    id: 'clientUser1',
    accountId: Accounts[0].id!,
    name: 'Санса Старк',
    email: 'sansa.stark@example.org',
    telegramId: 2345,
  },
  {
    id: 'clientUser2',
    accountId: Accounts[0].id!,
    name: 'Тайвин Ланнистер',
    email: 'taiwin.lannister@example.org',
    telegramId: 3456,
  },
  {
    id: 'clientUser3',
    accountId: Accounts[0].id!,
    name: 'Теон Грейджой',
    email: 'teon.grayjoy@example.org',
    telegramId: 4567,
  },
];
