import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import * as VKID from '@vkid/sdk';
import { loginVk } from '../../slicers/authSlice';
import { store } from '../../store';
import useVkAuthorize from './useVkAuthorize';
import { useAppSelector } from '../../hook';
import { codeVerifier } from './codeVerifier';
import { Box, Button, CircularProgress } from '@mui/material';
import { routes } from '../../routes';

type Props = {
  loginRedirect: string;
};

const VkAuthRedirectPage: React.FC<Props> = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const vkAuthorize = useVkAuthorize();

  // Get rid of the link to the app code
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!vkAuthorize.isIdle) {
      return;
    }

    const code = query.get('code')!;
    const device_id = query.get('device_id')!;
    const state = query.get('state');
    const vkidConfig = VKID.Config.get();

    if (vkidConfig.state !== state) {
      throw new Error('Invalid state');
    }

    vkAuthorize.mutate(
      {
        config: vkidConfig,
        codeVerifier: codeVerifier,
        code: code,
        deviceId: device_id,
      },
      {
        onSuccess: async (tokens) => {
          await store.dispatch(
            loginVk({
              accessToken: tokens.access_token,
            }),
          );
        },
        onError: (error) => {
          console.error(error);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!vkAuthorize.isError && !auth.error ? (
        <Box
          sx={{ height: '100vh' }}
          className="flex flex-col justify-center items-center"
        >
          <CircularProgress />
          <h1>Вход в Time2You...</h1>

          {auth.user && <Navigate to={props.loginRedirect} />}
        </Box>
      ) : (
        <Box
          sx={{ height: '100vh' }}
          className="flex flex-col justify-center items-center"
        >
          <h1>Что-то пошло не так 😿</h1>
          <Button LinkComponent={'a'} href={routes.login}>
            Попробовать снова
          </Button>
        </Box>
      )}
    </>
  );
};

export default VkAuthRedirectPage;
