import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import AppService from '../../services/appService';
import { useAppSelector } from '../../hook';
import { bookingDbToVM } from '../mapping';

export default function useBookings() {
  const currentUser = useAppSelector((x) => x.auth.user);

  return useQuery({
    queryKey: [queryKeys.bookings],
    queryFn: async () => {
      const service = new AppService();
      const bookings = await service.getBookingsByAccountId(
        currentUser?.accountId!,
      );
      return bookings.map((x) => bookingDbToVM(x));
    },
    select: (result) => {
      return result;
    },
  });
}
