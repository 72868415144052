import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import AppService from '../../services/appService';
import { useAppSelector } from '../../hook';
import { experienceDbToVM } from '../mapping';

export default function useExperiences() {
  const currentUser = useAppSelector((x) => x.auth.user);

  const experiencesQuery = useQuery({
    queryKey: [queryKeys.experiences],
    enabled: !!currentUser,
    queryFn: async () => {
      const service = new AppService();
      const experiences = await service.getExperiencesByAccountId(
        currentUser?.accountId!,
      );
      return experiences.map((x) => experienceDbToVM(x));
    },
    select: (result) => {
      return result;
    },
  });

  return experiencesQuery;
}
