import React from 'react';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import * as yup from 'yup';
import { useEditForm } from '../../../../../hooks/useEditForm';
import useExperience from '../../../../../queryHelpers/queries/useExperience';
import useUpdateExperience from '../../../../../queryHelpers/mutations/useUpdateExperience';
import { DateAvailabilityVM } from '../../../../../viewModels/experienceViewModel';
import DateAvailabilityEditor from './DateAvailabilityEditor';
import { DateString } from 'shared-ts/entities';

type WeekDayAvailabilityViewEditProps = {
  experienceId: string;
};

export const WeekDayAvailabilityFormSchema = yup.object({}).required();

export type WeekDayAvailabilityFormInputs = {
  dateAvailabilities: DateAvailabilityVM[];
};

const WeekDayAvailabilityViewEdit: React.FC<
  WeekDayAvailabilityViewEditProps
> = (props) => {
  const experienceQuery = useExperience({ id: props.experienceId });
  const {
    form: {
      register,
      handleSubmit,
      watch,
      trigger,
      formState: { isValid },
    },
    handleCancel,
  } = useEditForm<WeekDayAvailabilityFormInputs>({
    defaultValues: {
      dateAvailabilities: experienceQuery.data?.dateAvailability,
    },
    formSchema: WeekDayAvailabilityFormSchema,
  });
  const [defaultDate, setDefaultDate] = React.useState<DateString | null>(null);

  const updateUserSettingsMutation = useUpdateExperience(props.experienceId);

  const onSubmit = (data: WeekDayAvailabilityFormInputs) => {
    updateUserSettingsMutation.mutate({
      dateAvailability: data.dateAvailabilities,
    });
  };

  return (
    <ViewEditControl
      title="Расписание"
      view={
        <DateAvailabilityEditor
          value={experienceQuery.data?.dateAvailability ?? []}
          defaultDate={defaultDate}
          onSelectedDateChange={(date) => setDefaultDate(date)}
        />
      }
      editor={
        <DateAvailabilityEditor
          edit={{
            register,
            trigger,
            watch,
          }}
          defaultDate={defaultDate}
          onSelectedDateChange={(date) => setDefaultDate(date)}
        />
      }
      editMode="inline"
      editorIsValid={isValid}
      onCancel={handleCancel}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default WeekDayAvailabilityViewEdit;
