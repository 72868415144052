import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

type Props = {
  path: string;
};

const BackNavigationButton: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(props.path);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleBack}
        edge="start"
      >
        <ArrowBack color="action" />
      </IconButton>
    </>
  );
};

export default BackNavigationButton;
