import React from 'react';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ViewEditControl from '../../../components/viewEditControl/ViewEditControl';
import UserSettingsEditor from './UserSettingsEditor';
import useUser from '../../../../../queryHelpers/queries/useUser';
import * as yup from 'yup';
import useUpdateUserSettings from '../../../../../queryHelpers/mutations/useUpdateUserSettings';
import { useEditForm } from '../../../../../hooks/useEditForm';

type UserSettingsViewEditProps = {
  userId: string;
};

const UserSettingsFormSchema = yup
  .object({
    name: yup.string().required('Введите имя'),
    email: yup
      .string()
      .email('Введите валидный email адрес')
      .required('Введите email адрес'),
  })
  .required();

export type UserSettingsFormInputs = {
  name: string;
  email: string;
};

const UserSettingsViewEdit: React.FC<UserSettingsViewEditProps> = (props) => {
  const userQuery = useUser(props.userId);
  const {
    form: {
      register,
      handleSubmit,
      formState: { errors, isValid },
    },
    handleCancel
  } = useEditForm<UserSettingsFormInputs>({
    defaultValues: userQuery.data,
    formSchema: UserSettingsFormSchema,
  });

  const updateUserSettingsMutation = useUpdateUserSettings(props.userId);

  const onSubmit = (data: UserSettingsFormInputs) => {
    updateUserSettingsMutation.mutate({
      ...userQuery.data!,
      ...data,
    });
  };

  return (
    <ViewEditControl
      title="Личная информация"
      editMode='inline'
      viewFields={[
        {
          icon: <PersonIcon />,
          label: 'Имя',
          value: userQuery.data?.name || '',
        },
        {
          icon: <EmailIcon />,
          label: 'Email',
          value: userQuery.data?.email || '',
        },
      ]}
      editor={
        <UserSettingsEditor
          user={userQuery.data!}
          register={register}
          errors={errors}
        />
      }
      editorIsValid={isValid}
      onCancel={handleCancel}
      onSave={handleSubmit(onSubmit)}
    />
  );
};

export default UserSettingsViewEdit;
