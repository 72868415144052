import localforage from 'localforage';
import { Accounts } from './accounts';
import { Bookings } from './bookings';
import { Experiences } from './experiences';
import { Users } from './users';

type Key = 'Accounts' | 'Bookings' | 'Experiences' | 'Users';

export const DataStorage = {
  get: async <T>(key: Key): Promise<T[]> => {
    return await localforage.getItem(key) as T[];
  },

  set: async (key: Key, items: any) => {
    await localforage.setItem(key, items);
  },

  add: async (key: Key, item: any) => {
    const items = await DataStorage.get(key) || [];
    items.push(item);
    await DataStorage.set(key, items);
  },

  reset: async () => {
    await localforage.clear();
    await DataStorage.set('Accounts', Accounts);
    await DataStorage.set('Bookings', Bookings);
    await DataStorage.set('Experiences', Experiences);
    await DataStorage.set('Users', Users);
  },

  initialize: async () => {
    if (!await DataStorage.get('Accounts')) {
      await DataStorage.reset();
    }
  },
};

// Call this function when your app starts
DataStorage.initialize();
