// site/theme.ts

import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/x-date-pickers/locales';

// Default theme https://mui.com/material-ui/customization/default-theme/
// Standard https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
const theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#00BFFF',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#4DB6AC',
        contrastText: '#FFF',
      },
      background: {
        default: '#FAFAFA',
        paper: '#fff',
      },
    },
    shape: {
      borderRadius: 10,
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 200,
            backgroundColor: '#F5F5F5',
            color: '#fff',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            backgroundColor: '#FFF',
            color: '#000',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#F0F0F0',
            color: '#000',
            // border: 1,
          },
        },
      },
      MuiBottomNavigation: {
        defaultProps: {
          style: {
            backgroundColor: '#F0F0F0',
            color: '#000',
          },
        },
      },
    },
  },
  ruRU,
);

export default theme;
