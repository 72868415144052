import React, { useEffect, useMemo, useState } from 'react';
import { format, startOfToday } from 'date-fns';
import { getTimeSlotsForSelection } from '../../helpers/timeslotsHelpers';

import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
  Divider,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { AvailabilityViewModel, DailyTimeSlotsViewModel } from '../../viewModels/availabilityViewModel';
import { useAppSelector } from '../../hook';
import UserLoginStatus from './UserLoginStatus';
import TimeSlotSelector from './TimeSlotSelector';
import { DayCalendarSelector } from './DayCalendarSelector';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from '../PhoneInput';
import useUpsertBooking from '../../queryHelpers/mutations/useUpsertBooking';
import useBooking from '../../queryHelpers/queries/useBooking';
import useExperience from '../../queryHelpers/queries/useExperience';
import BookingConfirmationDialog from './BookingConfirmationDialog';
import { TimeSlotVM } from '../../viewModels/simpleTypes';
import { BookingVM } from '../../viewModels/bookingViewModel';
import useAvailableTimeSlots from '../../queryHelpers/queries/useAvailableTimeSlots';
import { DateString } from 'shared-ts/entities';

interface BookingFormProps {
  experienceId: string;
  bookingId?: string;
  onSave: (bookingId: string) => void;
}

const BookingFormFormSchema = yup
  .object({
    date: yup.string().required('Укажите дату'),
    timeSlot: yup
      .object<TimeSlotVM | null>({
        start: yup.number().required('Укажите время'),
        end: yup.number().required('Укажите время'),
      })
      .required('Укажите время'),
    name: yup.string().required('Укажите имя'),
    phone: yup
      .string()
      .min(11, 'Укажите полный номер телефона')
      .required('Укажите номер телефона'),
    wishes: yup.string(),
  })
  .required();

export type BookingFormFormInput = Pick<
  BookingVM,
  'date' | 'name' | 'phone' | 'wishes'
> &
  Partial<Pick<BookingVM, 'timeSlot'>>;

const BookingForm: React.FC<BookingFormProps> = (props) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<BookingFormFormInput>({
    defaultValues: {
      date: format(startOfToday(), 'yyyy-MM-dd'),
      name: '',
      phone: '',
      wishes: '',
    },
    resolver: yupResolver(BookingFormFormSchema) as any,
  });
  const clientName = watch('name');
  const date = watch('date');

  const user = useAppSelector((x) => x.auth.user);
  const experienceQuery = useExperience({
    id: props.experienceId,
    link: props.experienceId,
  });
  const bookingQuery = useBooking(props.bookingId);
  const upsertBookingMutation = useUpsertBooking();
  const availableTimeSlotsQuery = useAvailableTimeSlots(props.experienceId, date);

  const dailyAvailabilities: AvailabilityViewModel = useMemo(() => {
    if (!availableTimeSlotsQuery.data || !experienceQuery.data) {
      return { days: [] };
    }

    const experienceDuration = experienceQuery.data.summary.duration!;

    const days: DailyTimeSlotsViewModel[] = availableTimeSlotsQuery.data.map(availability => ({
      date: availability.date,
      timeSlots: getTimeSlotsForSelection(availability.timeSlots.map(slot => ({
        start: slot.startTime,
        end: slot.endTime
      })), experienceDuration)
    }));

    return { days };
  }, [availableTimeSlotsQuery.data, experienceQuery.data]);

  useEffect(() => {
    if (!date) {
      setValue('date', format(startOfToday(), 'yyyy-MM-dd') as DateString);
    }
  }, [date, setValue]);

  useEffect(() => {
    if (dailyAvailabilities.days.length > 0) {
      const selectedDayAvailability = dailyAvailabilities.days.find(day => day.date === date);
      if (!selectedDayAvailability || selectedDayAvailability.timeSlots.length === 0) {
        const firstAvailableDay = dailyAvailabilities.days.find(day => day.timeSlots.length > 0);
        if (firstAvailableDay) {
          setValue('date', firstAvailableDay.date);
        }
      }
    }
  }, [dailyAvailabilities, date, setValue]);

  useEffect(() => {
    if (clientName === '' && user) {
      setValue('name', user.name);
    }
  }, [user, clientName, setValue]);

  const handleBook = async () => {
    trigger();
    if (!isValid) {
      return;
    }

    setOpenConfirmation(true);
  };

  const handleConfirm = async () => {
    setOpenConfirmation(false);
    await upsertBookingMutation.mutateAsync(
      {
        ...bookingQuery.data,
        ...watch(),
        experienceId: experienceQuery.data?.id!,
        id: props.bookingId,
      },
      {
        onSettled: () => setOpenProgress(false),
        onSuccess: (bookingId) => {
          props.onSave(bookingId);
        },
      },
    );
  };

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <Paper sx={{ p: 2, width: '50vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {experienceQuery.data?.summary.title}
            </Typography>
            <Typography variant="overline">
              {experienceQuery.data?.summary.description}
            </Typography>
            <Divider flexItem sx={{ ml: -2, mr: -2 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" align="center">
              На какой день хотите записаться?
            </Typography>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DayCalendarSelector
                  availabilities={dailyAvailabilities}
                  value={field.value}
                  onChange={(x) => field.onChange(x)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: -4 }}>
            <Box>
              <Typography variant="caption" align="center">
                На какое время хотите записаться?
              </Typography>
              <Controller
                name="timeSlot"
                control={control}
                render={({ field }) => (
                  <TimeSlotSelector
                    slots={
                      dailyAvailabilities.days.find((x) => x.date === date)
                        ?.timeSlots || []
                    }
                    value={field.value ?? null}
                    onChange={(value) => field.onChange(value)}
                    helperText={errors.timeSlot?.message}
                    error={!!errors.timeSlot}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">Укажите пожелания</Typography>
            <TextField
              multiline
              size="small"
              {...register('wishes')}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Укажите аккаунт для напоминаний и связи
            </Typography>
            <Box>
              <UserLoginStatus />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Укажите, как к Вам обращаться
            </Typography>
            <TextField
              size="small"
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Укажите номер телефона для связи
            </Typography>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  value={field.value}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleBook}
            >
              Записаться
            </Button>
          </Grid>
        </Grid>

        {experienceQuery.data && isValid && (
          <BookingConfirmationDialog
            open={openConfirmation}
            onConfirm={handleConfirm}
            booking={watch()}
            experience={experienceQuery.data?.summary!}
            onCancel={() => setOpenConfirmation(false)}
          />
        )}

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openProgress}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Paper>
    </Grid>
  );
};

export default BookingForm;
