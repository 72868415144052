import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import CodeIcon from '@mui/icons-material/Code';
import { routes } from '../../../routes';

const menuItems = [
  {
    title: 'Записи',
    icon: <EventIcon />,
    path: routes.admin.bookings,
  },
  {
    title: 'Услуги',
    icon: <EditCalendarIcon />,
    path: routes.admin.experiences,
  },
  {
    title: 'Настройки',
    icon: <SettingsIcon />,
    path: routes.admin.settings,
  },
  {
    title: 'Разработчик',
    icon: <CodeIcon />,
    path: routes.admin.developer,
  },
];

const MenuNavigationButton: React.FC = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const navigate = useNavigate();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        edge="start"
      >
        <MenuIcon color="action" />
      </IconButton>

      <Box component="nav">
        <Menu
          open={menuOpen}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.path}
              selected={item.path === window.location.pathname}
              onClick={() => {
                navigate(item.path);
                setMenuAnchorEl(null);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default MenuNavigationButton;
