import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  LinearProgress,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { FormParams } from '../../../types';

export type AppFullScreenDialogProps = {
  title: string;
  children?: React.ReactNode;
  submitLabel: string;
  onSubmitted: () => void;
  isPendingSubmit?: boolean;
  isPending?: boolean;
  formParams: FormParams;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppFullScreenDialog: React.FC<AppFullScreenDialogProps> = (props) => {
  return (
    <Dialog
      fullScreen
      open={props.formParams.open}
      onClose={() => props.formParams.onClose()}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }} variant="elevation" elevation={1}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => props.formParams.onClose()}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
          <LoadingButton
            autoFocus
            loading={props.isPendingSubmit}
            variant="contained"
            onClick={props.onSubmitted}
          >
            {props.submitLabel}
          </LoadingButton>
        </Toolbar>
        {props.isPending && <LinearProgress />}
      </AppBar>
      <Box className="mt-16">{props.children}</Box>
    </Dialog>
  );
};

export default AppFullScreenDialog;
